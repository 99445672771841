import ContactPage from '@pretto/bricks/website/contact/pages/ContactPage'

import PropTypes from 'prop-types'
import { memo } from 'react'

import Layout from '../components/Layout'

// import SEO from '../../components/SEO'
// import Schema from '../../components/Schema'

const handleShowFront = () => {
  window.FrontChat('show')
}

const Contact = () => (
  <Layout>
    <ContactPage onShowFront={handleShowFront} />
  </Layout>
)

// <Schema data={data} pathname={pathname} />
// <SEO image={data.postTypeMeta.highlightedImage} {...data.seo} />
Contact.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default memo(Contact)
