import ContactHeader from '@pretto/bricks/website/contact/components/ContactHeader'
import ContactQuestions from '@pretto/bricks/website/contact/components/ContactQuestions'
import FooterCta from '@pretto/bricks/website/shared/components/FooterCta'

import PropTypes from 'prop-types'
import { Fragment, memo } from 'react'

const ContactPage = ({ onShowFront }) => (
  <>
    <ContactHeader onShowFront={onShowFront} />

    <ContactQuestions />

    <FooterCta />
  </>
)

ContactPage.propTypes = {
  onShowFront: PropTypes.func,
}

export default memo(ContactPage)
