import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Heading from '@pretto/bricks/components/typography/Heading'
import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'

import { LANDING_PAGE_SEO } from '@pretto/website/src/config/pagesUrls'

import PropTypes from 'prop-types'

import * as S from './styles'

const FooterCta = ({ simulationDuration = '5 minutes', ...props }) => (
  <S.Wrapper {...props}>
    <Wrapper full>
      <S.Content>
        <S.Headline>
          <Heading variant="white">
            {`Trouvez le meilleur prêt immobilier, en ${simulationDuration} minutes`},
            <br />
            avec nos experts
          </Heading>
        </S.Headline>

        <S.Cta>
          <S.Indicator>
            <ResponsiveSSR max="tablet">
              <Emoji size="medium">:point_down:</Emoji>
            </ResponsiveSSR>
            <ResponsiveSSR min="tablet">
              <Emoji size="medium">:point_right:</Emoji>
            </ResponsiveSSR>
          </S.Indicator>

          <S.Button>
            <ButtonLegacy size="large" variant="white" href={LANDING_PAGE_SEO}>
              <S.ButtonText>Simuler mon prêt</S.ButtonText>
            </ButtonLegacy>
          </S.Button>
        </S.Cta>
      </S.Content>
    </Wrapper>
  </S.Wrapper>
)

FooterCta.propTypes = {
  simulationDuration: PropTypes.number,
}

export default FooterCta
