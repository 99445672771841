// extracted by mini-css-extract-plugin
export var button = "ButtonLegacy-module--button--sbj0m";
export var buttonAccent1 = "ButtonLegacy-module--button--accent-1--R-XuS";
export var buttonAccent1D = "ButtonLegacy-module--button--accent-1-d--IlYT1";
export var buttonAccent1L = "ButtonLegacy-module--button--accent-1-l--NdAaS";
export var buttonAccent2 = "ButtonLegacy-module--button--accent-2--PBSYn";
export var buttonAccent2D = "ButtonLegacy-module--button--accent-2-d--fRF-Q";
export var buttonAccent2L = "ButtonLegacy-module--button--accent-2-l--+xE4-";
export var buttonAccent3 = "ButtonLegacy-module--button--accent-3--xZDu8";
export var buttonAccent3D = "ButtonLegacy-module--button--accent-3-d--oBIs7";
export var buttonAccent3L = "ButtonLegacy-module--button--accent-3-l--D3oDy";
export var buttonAccent4 = "ButtonLegacy-module--button--accent-4--kqSKD";
export var buttonAccent420 = "ButtonLegacy-module--button--accent-4-20--uo3EL";
export var buttonAccent430 = "ButtonLegacy-module--button--accent-4-30--7IdMT";
export var buttonAccent440 = "ButtonLegacy-module--button--accent-4-40--cEcCv";
export var buttonAccent460 = "ButtonLegacy-module--button--accent-4-60--OKIJx";
export var buttonAccent480 = "ButtonLegacy-module--button--accent-4-80--gDk-n";
export var buttonAccent4D = "ButtonLegacy-module--button--accent-4-d--1WhhN";
export var buttonAccent4L = "ButtonLegacy-module--button--accent-4-l--SXCGr";
export var buttonAccent5 = "ButtonLegacy-module--button--accent-5--Eeq96";
export var buttonAccent5D = "ButtonLegacy-module--button--accent-5-d--1Q3jj";
export var buttonAccent5L = "ButtonLegacy-module--button--accent-5-l--V+uk4";
export var buttonCircle = "ButtonLegacy-module--button--circle--jw5Gx";
export var buttonDisabled = "ButtonLegacy-module--button--disabled--lklSh";
export var buttonError = "ButtonLegacy-module--button--error---r4yY";
export var buttonErrorD = "ButtonLegacy-module--button--error-d--OUiYD";
export var buttonErrorL = "ButtonLegacy-module--button--error-l--LUfuK";
export var buttonFacebook = "ButtonLegacy-module--button--facebook--SdL4f";
export var buttonFlat = "ButtonLegacy-module--button--flat--RUNzi";
export var buttonFull = "ButtonLegacy-module--button--full--yjRbv";
export var buttonInherit = "ButtonLegacy-module--button--inherit--uJIGW";
export var buttonLarge = "ButtonLegacy-module--button--large--hZYGz";
export var buttonLine = "ButtonLegacy-module--button--line--EvWnV";
export var buttonLowercase = "ButtonLegacy-module--button--lowercase--k-pLb";
export var buttonMedium = "ButtonLegacy-module--button--medium---G96t";
export var buttonNeutral1 = "ButtonLegacy-module--button--neutral-1--alDO0";
export var buttonNeutral120 = "ButtonLegacy-module--button--neutral-1-20--fREw7";
export var buttonNeutral130 = "ButtonLegacy-module--button--neutral-1-30--TuZ9W";
export var buttonNeutral140 = "ButtonLegacy-module--button--neutral-1-40--EIW9U";
export var buttonNeutral160 = "ButtonLegacy-module--button--neutral-1-60--K-PYu";
export var buttonNeutral180 = "ButtonLegacy-module--button--neutral-1-80--pKqpi";
export var buttonNeutral1D = "ButtonLegacy-module--button--neutral-1-d--J0n1N";
export var buttonNeutral1L = "ButtonLegacy-module--button--neutral-1-l--APjan";
export var buttonPrimary1 = "ButtonLegacy-module--button--primary-1--Y4WOg";
export var buttonPrimary120 = "ButtonLegacy-module--button--primary-1-20--Onkfc";
export var buttonPrimary130 = "ButtonLegacy-module--button--primary-1-30--xbDbL";
export var buttonPrimary140 = "ButtonLegacy-module--button--primary-1-40--aJdja";
export var buttonPrimary160 = "ButtonLegacy-module--button--primary-1-60--VsqVW";
export var buttonPrimary180 = "ButtonLegacy-module--button--primary-1-80--Gf4Sl";
export var buttonPrimary1D = "ButtonLegacy-module--button--primary-1-d--N1smU";
export var buttonPrimary1L = "ButtonLegacy-module--button--primary-1-l--C4-4D";
export var buttonPrimary2 = "ButtonLegacy-module--button--primary-2--vu9--";
export var buttonPrimary2D = "ButtonLegacy-module--button--primary-2-d--kGwts";
export var buttonPrimary2L = "ButtonLegacy-module--button--primary-2-l--1c7rO";
export var buttonScaleOnHover = "ButtonLegacy-module--button--scale-on-hover--ffmaG";
export var buttonShadow = "ButtonLegacy-module--button--shadow--GjHa7";
export var buttonSmall = "ButtonLegacy-module--button--small--Ak5VP";
export var buttonSocial = "ButtonLegacy-module--button--social--qRJvb";
export var buttonSquared = "ButtonLegacy-module--button--squared--mI2MG";
export var buttonSquaredIcon = "ButtonLegacy-module--button--squared-icon--BuXaX";
export var buttonSuccess = "ButtonLegacy-module--button--success--A532i";
export var buttonSuccessD = "ButtonLegacy-module--button--success-d--W3OBa";
export var buttonSuccessL = "ButtonLegacy-module--button--success-l--mbCwH";
export var buttonTransparent = "ButtonLegacy-module--button--transparent--w3J1+";
export var buttonTwitter = "ButtonLegacy-module--button--twitter--MW4ll";
export var buttonWarning = "ButtonLegacy-module--button--warning--NTpG9";
export var buttonWarningD = "ButtonLegacy-module--button--warning-d--1P451";
export var buttonWarningL = "ButtonLegacy-module--button--warning-l--oZ0FD";
export var buttonWhatsapp = "ButtonLegacy-module--button--whatsapp--zSw-3";
export var buttonWhite = "ButtonLegacy-module--button--white--N1c-h";
export var buttonXSmall = "ButtonLegacy-module--button--x-small--3oNb9";
export var button__iconLabel = "ButtonLegacy-module--button__icon-label--iDq8E";
export var button__iconLarge = "ButtonLegacy-module--button__icon--large--bTikj";
export var button__iconLeft = "ButtonLegacy-module--button__icon--left--xLrwU";
export var button__iconMedium = "ButtonLegacy-module--button__icon--medium--se1Hr";
export var button__iconRight = "ButtonLegacy-module--button__icon--right--bf5DV";
export var button__iconSmall = "ButtonLegacy-module--button__icon--small--1bQ62";
export var button__text = "ButtonLegacy-module--button__text--mScbG";
export var button__textAccent1 = "ButtonLegacy-module--button__text--accent-1--oZedc";
export var button__textAccent1D = "ButtonLegacy-module--button__text--accent-1-d--T7y3q";
export var button__textAccent1L = "ButtonLegacy-module--button__text--accent-1-l--qOPo1";
export var button__textAccent2 = "ButtonLegacy-module--button__text--accent-2--XXOzt";
export var button__textAccent2D = "ButtonLegacy-module--button__text--accent-2-d--TU6bu";
export var button__textAccent2L = "ButtonLegacy-module--button__text--accent-2-l--nmQQ-";
export var button__textAccent3 = "ButtonLegacy-module--button__text--accent-3--C5dqa";
export var button__textAccent3D = "ButtonLegacy-module--button__text--accent-3-d--pyCES";
export var button__textAccent3L = "ButtonLegacy-module--button__text--accent-3-l--E1Ifs";
export var button__textAccent4 = "ButtonLegacy-module--button__text--accent-4--bHLSs";
export var button__textAccent420 = "ButtonLegacy-module--button__text--accent-4-20--7bc0Y";
export var button__textAccent430 = "ButtonLegacy-module--button__text--accent-4-30--dJOBb";
export var button__textAccent440 = "ButtonLegacy-module--button__text--accent-4-40--IlSGg";
export var button__textAccent460 = "ButtonLegacy-module--button__text--accent-4-60--PWTKv";
export var button__textAccent480 = "ButtonLegacy-module--button__text--accent-4-80--3taDR";
export var button__textAccent4D = "ButtonLegacy-module--button__text--accent-4-d--Ujxaj";
export var button__textAccent4L = "ButtonLegacy-module--button__text--accent-4-l--hkDvl";
export var button__textAccent5 = "ButtonLegacy-module--button__text--accent-5--o8SU5";
export var button__textAccent5D = "ButtonLegacy-module--button__text--accent-5-d--IlHEo";
export var button__textAccent5L = "ButtonLegacy-module--button__text--accent-5-l--NYnLS";
export var button__textError = "ButtonLegacy-module--button__text--error--loElw";
export var button__textErrorD = "ButtonLegacy-module--button__text--error-d--7-jJv";
export var button__textErrorL = "ButtonLegacy-module--button__text--error-l--JxG4n";
export var button__textFacebook = "ButtonLegacy-module--button__text--facebook--wcfh6";
export var button__textInherit = "ButtonLegacy-module--button__text--inherit--jZ73o";
export var button__textNeutral1 = "ButtonLegacy-module--button__text--neutral-1--u6LN7";
export var button__textNeutral120 = "ButtonLegacy-module--button__text--neutral-1-20--aIpJU";
export var button__textNeutral130 = "ButtonLegacy-module--button__text--neutral-1-30--sioZl";
export var button__textNeutral140 = "ButtonLegacy-module--button__text--neutral-1-40--WFj8S";
export var button__textNeutral160 = "ButtonLegacy-module--button__text--neutral-1-60--FhNmJ";
export var button__textNeutral180 = "ButtonLegacy-module--button__text--neutral-1-80--T-MM8";
export var button__textNeutral1D = "ButtonLegacy-module--button__text--neutral-1-d--vfv1p";
export var button__textNeutral1L = "ButtonLegacy-module--button__text--neutral-1-l--Pdk4T";
export var button__textPrimary1 = "ButtonLegacy-module--button__text--primary-1--FSgcI";
export var button__textPrimary120 = "ButtonLegacy-module--button__text--primary-1-20--IRhMw";
export var button__textPrimary130 = "ButtonLegacy-module--button__text--primary-1-30--XPypG";
export var button__textPrimary140 = "ButtonLegacy-module--button__text--primary-1-40--q0sbq";
export var button__textPrimary160 = "ButtonLegacy-module--button__text--primary-1-60--UhDHF";
export var button__textPrimary180 = "ButtonLegacy-module--button__text--primary-1-80--cxhxy";
export var button__textPrimary1D = "ButtonLegacy-module--button__text--primary-1-d--HA+7t";
export var button__textPrimary1L = "ButtonLegacy-module--button__text--primary-1-l--1uM03";
export var button__textPrimary2 = "ButtonLegacy-module--button__text--primary-2--MGww6";
export var button__textPrimary2D = "ButtonLegacy-module--button__text--primary-2-d--IrrSj";
export var button__textPrimary2L = "ButtonLegacy-module--button__text--primary-2-l--tP3PV";
export var button__textSuccess = "ButtonLegacy-module--button__text--success--Kyttr";
export var button__textSuccessD = "ButtonLegacy-module--button__text--success-d--ZbG+I";
export var button__textSuccessL = "ButtonLegacy-module--button__text--success-l--Btsr6";
export var button__textTwitter = "ButtonLegacy-module--button__text--twitter--KP2Gs";
export var button__textWarning = "ButtonLegacy-module--button__text--warning--IL93Y";
export var button__textWarningD = "ButtonLegacy-module--button__text--warning-d--uhoqG";
export var button__textWarningL = "ButtonLegacy-module--button__text--warning-l--JhVEe";
export var button__textWhatsapp = "ButtonLegacy-module--button__text--whatsapp--akIga";
export var button__textWhite = "ButtonLegacy-module--button__text--white--iP+G1";
export var icon = "ButtonLegacy-module--icon--9S-Kb";