import { breakpoints, g, numeralBreakpoints } from '@pretto/bricks/components/layout'
import Image from '@pretto/bricks/website/shared/components/Image'

import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(8)};
  }
`
export const Header = styled.header`
  padding: ${g(3)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(7)} 0;
  }
`
export const HeaderImage = styled(Image).attrs({ options: { width: numeralBreakpoints.mobileS } })`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    border-radius: ${g(1)};
    display: block;
    margin-right: ${g(3)};
    width: ${breakpoints.mobileS};
  }
`
export const Title = styled.span`
  display: flex;
  margin-top: ${g(2)};

  > * {
    margin-right: ${g(3)};
  }
`
export const Link = styled.p`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.legacy.colors.primary1.default};
  }
`
export const Information = styled.div`
  padding-top: ${g(1)};
  max-width: ${breakpoints.mobileS};
`
